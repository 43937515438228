import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const swiper = new Swiper('.js-projects-list', {
  modules: [Navigation, Pagination],
  navigation: {
    nextEl: '.js-projects-button-next',
    prevEl: '.js-projects-button-prev'
  },
  spaceBetween: 16,
  slidesPerView: 'auto',
  slidesPerGroupAuto: false,
  centeredSlides: true,
  loop: true,
  pagination: {
    el: '.js-projects-pagination',
  },
  breakpoints: {
    960: {
      spaceBetween: 113,
    },

    780: {
      spaceBetween: 40,
    }
  },
});

// set fraction pagination

const pager = document.querySelector(".js-pager")
swiper.on('paginationUpdate', (slide) => {
   pager.innerHTML = slide.realIndex + 1;
});

