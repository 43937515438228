import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

let swiper;
swiper = new Swiper('.js-structures', {
  modules: [Navigation],
  navigation: {
    nextEl: '.js-structures-button-next',
    prevEl: '.js-structures-button-prev'
  },
  spaceBetween: 24,
  slidesPerView: 'auto',
  slidesPerGroupAuto: false,
});


