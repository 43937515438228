// import { throttle, debounce } from 'throttle-debounce';

// import "./front/components/navigation";
import browserUpdate from 'browser-update/update.npm';
import "./front/components/slider/slider-structure";
import "./front/components/slider/slider-projects";



// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: -2, // Edge
    i: 12, // IE
    f: -2, // Firefox
    s: -2, // Safari
    c: -2, // Chrome
  },
  insecure: true,
});

